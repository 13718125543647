import classNames from "classnames";
import { FC } from "react";

type MenuItem = {
  label: string;
  onClick: () => void;
};

type SectionMenuDesktopProps = {
  items: MenuItem[];
  onClickMenuItem: (item: MenuItem) => void;
};

const SectionMenuDesktop: FC<SectionMenuDesktopProps> = (props) => {
  return (
    <div
      className={classNames([
        "absolute",
        "bg-black",
        "transform",
        "left-1/2",
        "-translate-x-1/2",
        "py-2",
        "z-50",
      ])}
    >
      {props.items.map((item) => {
        return (
          <button
            key={item.label}
            onClick={() => props.onClickMenuItem(item)}
            className={classNames([
              "px-5",
              "py-1",
              "text-base",
              "whitespace-nowrap",
              "hover:bg-black-light",
              "cursor-pointer",
              "select-none",
              "w-full",
              "text-left",
            ])}
          >
            {item.label}
          </button>
        );
      })}
    </div>
  );
};

export default SectionMenuDesktop;

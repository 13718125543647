import classNames from "classnames";
import dynamic from "next/dynamic";
import Script from "next/script";
import React, { FC, useMemo, useState } from "react";
import { useLocalStorage } from "react-use";
import { useAd } from "../../utils/AdsContext";
import { replaceAll } from "../../utils/replaceAll";
import { AdvertisementProps } from '.';

const AdNotFound: FC<{ id: number }> = (props) => {
  return (
    <div
      className={classNames("w-full", "flex", "justify-center", "items-center")}
    >
      <p className={classNames("text-red")}>Error: Ad: {props.id} not found</p>
    </div>
  );
};

const AdvertisementBase: FC<AdvertisementProps> = (props) => {
  const ad = useAd(props.id);
  const date = useMemo(() => String(Number(new Date())), []);
  const [debug] = useLocalStorage("ad-debug");
  const [showCode, setShowCode] = useState(false);

  if (!ad) return <AdNotFound id={props.id} />;
  if (!ad.online) return null;

  let code = ad.code;
  if (
    code &&
    props.codeReplaceAll &&
    typeof props.codeReplaceAll === "function"
  ) {
    const codeReplaceMap = props.codeReplaceAll();
    Object.keys(codeReplaceMap).forEach((key) => {
      code = replaceAll(code || "", `{{${key}}}`, codeReplaceMap[key]);
    });
  }

  const scripts = code
    ? code
        .match(/<script[^>]*>([\s\S]*?)<\/script>/gi)
        ?.map((script) => script.replace(/(<script.*>|<\/script>)/g, ""))
    : null;

  const markup =
    code?.replace?.(/<script[^>]*>([\s\S]*?)<\/script>/gi, "") || "";

  const toggleShowCode = () => setShowCode((showCode) => !showCode);

  return (
    <div
      className={classNames(
        "w-full",
        "flex",
        "flex-wrap",
        "justify-center",
        "items-center",
        {
          "border-2": debug,
          "border-dashed": debug,
          "border-red": debug,
        }
      )}
    >
      {debug && (
        <div className={classNames("w-full")}>
          <p>{JSON.stringify({ ...ad, code: undefined }, null, 2)}</p>
          <button
            onClick={toggleShowCode}
            className={classNames(
              "hover:underline",
              "py-1",
              "px-2",
              "bg-green",
              "my-2",
              "text-white"
            )}
          >
            {showCode ? "Hide code" : "Show code"}
          </button>
          {showCode && (
            <pre
              className={classNames(
                "text-sm",
                "overflow-auto",
                "bg-gray-100",
                "p-2",
                "whitespace-pre-wrap"
              )}
            >
              {code}
            </pre>
          )}
        </div>
      )}

      {Boolean(scripts?.length || markup) && (
        <>
          <div
            className={classNames("w-full", "flex", "flex-col", "items-center")}
            dangerouslySetInnerHTML={{
              __html: markup!,
            }}
          />
          {Boolean(scripts && scripts.length) &&
            scripts!.map((script, index) => {
              let scriptId = `${ad.id}-script-${index}-${date}`;
              const tagId = props.codeReplaceAll?.()?.tagId;
              if (tagId) scriptId += `-${tagId}`;

              return (
                <Script key={scriptId} id={scriptId}>
                  {`
                      function main() {
                        ${script}
                        console.log("Loading ad script with following config:");
                        console.log(${JSON.stringify({
                          adId: ad.id,
                          tagId: tagId,
                        })});
                      };
                      setTimeout(main, 100);
                    `}
                </Script>
              );
            })}
        </>
      )}
    </div>
  );
};

export default dynamic(
  () => Promise.resolve(React.memo(AdvertisementBase, () => true)),
  {
    ssr: false,
  }
);

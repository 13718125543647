import { createContext, useContext } from "react";
import { API } from "../services/api/types";

export const AdsContext = createContext<null | API.Ad[]>(null);

export const useAd = (id: number) => {
  const ads = useContext(AdsContext);
  if (ads === null) {
    throw new Error("useAds must be used within an AdsProvider");
  }
  const ad = ads.find((ad) => ad.id === id);
  return ad;
};

import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import { FC } from "react";
import { createImageSizes } from "../../utils/image";
import { BreakingTeaser } from "./types";

type BreakingTeaserProps = {
  teaser: BreakingTeaser;
};

const BreakingTeaser: FC<BreakingTeaserProps> = (props) => {
  return (
    <Link href={props.teaser.link} passHref>
      <a
        className={classNames("flex", "items-center", "hover:underline")}
        rel={
          props.teaser.link.startsWith("https://") ? "nofollow sponsored" : ""
        }
        target={props.teaser.link.startsWith("https://") ? "_blank" : ""}
      >
        {props.teaser.image && (
          <div className="relative h-[40px] w-[80px] flex-shrink-0 mr-3">
            <Image
              layout="fill"
              src={props.teaser.image}
              alt=""
              className="object-contain"
              sizes={createImageSizes({
                mobile: "15vw",
                tablet: "15vw",
                desktop: "15vw",
              })}
            />

            <span className="text-[10px] absolute -bottom-4 inset-x-0 text-center">
              Annons.18+
            </span>
          </div>
        )}

        {props.teaser.category && (
          <div className={classNames("px-3", "py-1", "bg-black", "mr-3")}>
            <p
              className={classNames(
                "text-white",
                "text-base",
                "font-semibold",
                "whitespace-nowrap"
              )}
            >
              {props.teaser.category}
            </p>
          </div>
        )}

        <div>
        <p
          className={classNames(
            "text-base",
            "text-text-primary",
            "font-semibold"
          )}
        >
          {props.teaser.title}
        </p>

        {props.teaser.subtitle && <div className='text-xs'>{props.teaser.subtitle}</div>}
        </div>
      </a>
    </Link>
  );
};

export default BreakingTeaser;

import AdsIds from "../data/ads";
import { API } from "../services/api/types";
import { randomNumber } from "./number";

export const getDynamicMobileTagId = () => {
  return `fotbolltransfers_mobil2_${randomNumber(1, Math.pow(10, 6))}`;
};

export const getAdvertsiementHeight = (id: API.Ad["id"]) => {
  if ([AdsIds.panorama_1].includes(id)) {
    return 120;
  }

  if ([AdsIds.mobile_1, AdsIds.mobile_2].includes(id)) {
    return 160;
  }

  if ([AdsIds.dynamic_desktop, AdsIds.dynamic_mobile].includes(id)) {
    return 250;
  }
};

import { FC } from "react";
import MobileMenuOverlay from "../DropdownMenu/MobileMenuOverlay";
import NavigationLink from "../Navigation/NavigationLink";
import { MenuItem } from "./SectionMenuButton";

type SectionMenuMobileProps = {
  items: MenuItem[];

  onClickMenuItem: (item: MenuItem) => void;
  onClickClose: () => void;
};

const SectionMenuMobile: FC<SectionMenuMobileProps> = (props) => {
  return (
    <MobileMenuOverlay onClickClose={props.onClickClose}>
      {props.items.map((item) => {
        return (
          <NavigationLink
            key={item.label}
            isActive={false}
            label={item.label}
            onClick={() => props.onClickMenuItem(item)}
          ></NavigationLink>
        );
      })}
    </MobileMenuOverlay>
  );
};

export default SectionMenuMobile;

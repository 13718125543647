import classNames from "classnames";
import { FC, useRef, useState } from "react";
import { useInterval } from "react-use";

const StickySidebar: FC = ({ children }) => {
  const wrapperRef = useRef(null);
  const [height, setHeight] = useState(0);

  useInterval(() => {
    setHeight((wrapperRef.current as any)?.clientHeight || 0);
  }, 1000);

  const windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;

  let top = 0;

  if (height > windowHeight) top = -(height - windowHeight);

  return (
    <div ref={wrapperRef} style={{ top }} className={classNames("sticky")}>
      {children}
    </div>
  );
};

export default StickySidebar;

import classNames from "classnames";
import React, { FC, useContext, useRef, useState } from "react";
import { MdChevronRight } from "react-icons/md";
import { useOnClickOutside } from "../../hooks/onClickOutside";
import useBreakpoint from "../../hooks/useBreakpoint";
import { deviceTypeContext } from "../../utils/deviceTypeContext";
import ConditionalRendering from "../ConditionalRendering";
import { Media } from "../Media";
import SectionMenuDesktop from "./SectionMenuDesktop";
import SectionMenuMobile from "./SectionMenuMobile";

export type MenuItem = {
  label: string;
  onClick: () => void;
};

type SectionMenuButtonProps = {
  items: MenuItem[];
  activeItem: number;
};

const SectionMenuButton: FC<SectionMenuButtonProps> = (props) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const wrapperRef = useRef(null);
  const isAboveMd = useBreakpoint("md", useContext(deviceTypeContext)!);
  const hideMenu = () => setIsMenuVisible(false);
  const showMenu = () => setIsMenuVisible(true);
  useOnClickOutside(wrapperRef, isAboveMd ? hideMenu : () => {});
  const onClickMenuItem = (item: MenuItem) => {
    hideMenu();
    item.onClick();
  };

  return (
    <div className={classNames(["relative"])} ref={wrapperRef}>
      <div
        onClick={isMenuVisible ? hideMenu : showMenu}
        className={classNames([
          "flex",
          "items-center",
          "text-base",
          "px-2",
          "py-1/2",
          "bg-black-light",
          "rounded",
          "hover:bg-black-light-hover",
          "cursor-pointer",
          "select-none",
        ])}
      >
        <p className={classNames(["mr-1", "font-semibold"])}>
          {props.items[props.activeItem].label}
        </p>
        <MdChevronRight size={22} className={classNames(["rotate-90"])} />
      </div>

      <Media greaterThanOrEqual="md">
        <ConditionalRendering isVisible={isMenuVisible}>
          <SectionMenuDesktop
            items={props.items}
            onClickMenuItem={onClickMenuItem}
          />
        </ConditionalRendering>
      </Media>
      <Media lessThan="md">
        {isMenuVisible && (
          <SectionMenuMobile
            items={props.items}
            onClickClose={hideMenu}
            onClickMenuItem={onClickMenuItem}
          />
        )}
      </Media>
    </div>
  );
};

export default SectionMenuButton;

const AdsIds = {
  tws_1: 72,
  tws_2: 73,
  panorama_1: 60,
  panorama_2: 61,
  panorama_2_1: 62,
  panorama_2_2: 63,
  panorama_2_3: 64,
  panorama_2_4: 65,
  panorama_2_5: 66,
  panorama_2_6: 67,
  panorama_2_7: 68,
  panorama_2_8: 69,
  panorama_2_9: 70,
  panorama_2_10: 71,

  mobile_1: 75,
  mobile_2: 76,
  mobile_2_1: 77,
  mobile_2_2: 78,
  mobile_2_3: 79,
  mobile_2_4: 80,
  mobile_2_5: 81,
  mobile_2_6: 82,
  mobile_2_7: 83,
  mobile_2_8: 84,
  mobile_2_9: 85,
  mobile_2_10: 86,

  mpu: 74,

  desktop_textlink_top: 103,
  desktop_textlink_1: 87,
  desktop_textlink_2: 88,
  desktop_textlink_3: 89,
  desktop_textlink_4: 90,

  mobile_textlink_top: 104,
  mobile_textlink_1: 91,
  mobile_textlink_2: 92,
  mobile_textlink_3: 93,
  mobile_textlink_4: 94,

  desktop_vi: 95,
  mobile_vi: 96,

  desktop_native: 97,
  mobile_native: 98,

  casino_list: 99,

  dynamic_mobile: 100,
  dynamic_desktop: 101,

  tws_insider: 102,
};

export const AdsIdsMobileCollection = [
  AdsIds.mobile_2_1,
  AdsIds.mobile_2_2,
  AdsIds.mobile_2_3,
  AdsIds.mobile_2_4,
  AdsIds.mobile_2_5,
  AdsIds.mobile_2_6,
  AdsIds.mobile_2_7,
  AdsIds.mobile_2_8,
  AdsIds.mobile_2_9,
  AdsIds.mobile_2_10,
];

export const AdsIdsPanoramaCollection = [
  AdsIds.panorama_2_1,
  AdsIds.panorama_2_2,
  AdsIds.panorama_2_3,
  AdsIds.panorama_2_4,
  AdsIds.panorama_2_5,
  AdsIds.panorama_2_6,
  AdsIds.panorama_2_7,
  AdsIds.panorama_2_8,
  AdsIds.panorama_2_9,
  AdsIds.panorama_2_10,
];

export default AdsIds;

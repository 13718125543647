import { useRouter } from "next/router";

const useCanonicalUrl = () => {
  const router = useRouter();

  const pathWithoutParams = router.asPath.split("?")[0];

  return `${process.env.NEXT_PUBLIC_FRONTEND_URL}${
    pathWithoutParams === "/" ? "" : pathWithoutParams
  }`;
};

export default useCanonicalUrl;

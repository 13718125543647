import { API } from "../../services/api/types";
import AdvertisementBase from "./AdvertisementBase";
import AdvertisementWrapper from "./AdvertisementWrapper";

export type AdvertisementProps = {
  id: API.Ad["id"];

  // Replaces all occurences of the key with the value inside ad.code.
  codeReplaceAll?: () => { [key: string]: string };
};

const Advertisement = ({ id, codeReplaceAll }: AdvertisementProps) => {
  return (
    <AdvertisementWrapper id={id}>
      <AdvertisementBase id={id} codeReplaceAll={codeReplaceAll} />
    </AdvertisementWrapper>
  );
};

export default Advertisement;

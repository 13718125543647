export const removeEmptyEntries = (obj: any) => {
  if (!obj) return {};

  const result: any = {};

  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined) {
      result[key] = obj[key];
    }
  }

  return result;
};

export default {
  removeEmptyEntries,
};

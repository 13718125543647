import classNames from "classnames";
import { FC } from "react";
import { MdClose } from "react-icons/md";
import Overlay from "../Overlay";

type MobileMenuOverlayProps = {
  onClickClose: () => void;
};

const MobileMenuOverlay: FC<MobileMenuOverlayProps> = (props) => {
  return (
    <Overlay open onClose={() => {}}>
      <div className={classNames(["flex", "justify-end"])}>
        <button
          onClick={props.onClickClose}
          className={classNames(["p-1", "mr-1", "mt-1"])}
        >
          <MdClose color="black" size={26} />
        </button>
      </div>
      <div className={classNames("text-black", "text-base")}>
        {props.children}
      </div>
    </Overlay>
  );
};

export default MobileMenuOverlay;

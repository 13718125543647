import classNames from "classnames";
import React, { FC } from "react";
import { Media } from "../Media";

type PrimaryGridProps = {
  top: React.ReactElement;
  right: React.ReactElement;
  main: React.ReactElement;
};

const PrimaryGrid: FC<PrimaryGridProps> = (props) => {
  return (
    <div className={classNames(["flex", "tws:gap-5", "w-full", "mt-1"])}>
      <div className={classNames(["flex-1", "max-w-full"])}>
        {props.top}
        {props.main}
      </div>
      <Media style={{ width: 300 }} greaterThanOrEqual="tws">
        {props.right}
      </Media>
    </div>
  );
};

export default PrimaryGrid;

import classNames from "classnames";
import Link from "next/link";
import React, { createElement, FC } from "react";
import { FaRss } from "react-icons/fa";

type SectionHeaderProps = {
  label: string;
  hideLabelOnMobile?: boolean;
  menu?: React.ReactElement;
  icon?: React.ReactElement;
  rssFeed?: string;
  smallText?: boolean;
  labelTag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
  hideLeftBorder?: boolean;
};

const SectionHeader: FC<SectionHeaderProps> = (props) => {
  const classes = props.hideLabelOnMobile ? ["hidden", "sm:block"] : [];

  return (
    <header
      className={classNames([
        "flex",
        "items-center",
        "bg-black",
        "text-white",
        "text-lg",
        "md:text-xl",
        "border-green",
        "px-3",
        "py-2",
        "md:py-1",
        { "border-l-[8px]": !props.hideLeftBorder },
        {
          "text-xl": !props.smallText,
          "text-lg": props.smallText,
        },
      ])}
    >
      {createElement(
        props.labelTag || "p",
        { className: classNames("mr-1", "font-semibold", ...classes) },
        props.label
      )}
      {props.menu && (
        <>
          <div className={classNames("ml-1")} />
          {props.menu}
        </>
      )}
      <div className={classNames("ml-auto")}>{props.icon && props.icon}</div>
      {props.rssFeed && (
        <Link href={props.rssFeed}>
          <a className={classNames("ml-auto")}>
            <FaRss size={20} />
          </a>
        </Link>
      )}
    </header>
  );
};

export default SectionHeader;

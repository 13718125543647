import { removeEmptyEntries } from "../../utils/objectUtils";
import { API } from "./types";
import { fetchResource } from "./utils";

export const getLeagueNews =
  (leagueId: number) =>
  (options: {
    limit?: number;
    offset?: number;
    after?: string;
    position?: number;
  }) => {
    return fetchResource<API.Article[]>(`/leagues/${leagueId}/news`, {
      queryParams: removeEmptyEntries(options),
    });
  };

export const getLeague = async (id: string) => fetchResource(`/leagues/${id}`);

export const getLeagues = async () =>
  fetchResource<API.League[]>("/leagues/list");

export const getLeagueClubs = async (leagueId: string) => {
  return fetchResource<API.League[]>(`/leagues/${leagueId}/clubs`);
};

export const getLeagueTransfers = (leagueId: string) => {
  return fetchResource<API.Transfer[]>(`/leagues/${leagueId}/transfers`);
};

export const getLeagesByCountry = (q?: string | number) =>
  fetchResource(`/leagues/list`, {
    queryParams: removeEmptyEntries({ q }),
  });

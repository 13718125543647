import { PropsWithChildren } from "react";
import { API } from "../../services/api/types";
import { getAdvertsiementHeight } from "../../utils/ads";

type AdvertisementWrapperProps = {
  id: API.Ad["id"];
};

const AdvertisementWrapper = ({
  id,
  children,
}: PropsWithChildren<AdvertisementWrapperProps>) => {
  const height = getAdvertsiementHeight(id);

  return (
    <div
      style={{
        minHeight: height ? `${height}px` : "auto",
      }}
    >
      {children}
    </div>
  );
};

export default AdvertisementWrapper;
